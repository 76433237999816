<template>
  <el-dialog
    top="2em"
    width="80%"
    :visible.sync="dialogVisible"
    append-to-body
    title="Первичное оборудование/ЛЭП"
    class="incidient_primary_equip--modal"
    :close-on-click-modal="false"
  >
    <div class="my-flex-container-column">
      <select-primary-equipment-filter
        @active-change="filterActive = $event"
        :filter-model="filterModel"
        :with-line="!filterModel.hideLines"
        @clear-filters="clearFilters" />
      <select-primary-equipment-table
        :key="key"
        :filter-active="filterActive"
        :filter-model="filterModel"
        :items="items"
        :incident-id="incidentId"
        @change="change($event)"/>
    </div>
  </el-dialog>
</template>

<script>
import SelectPrimaryEquipmentFilter from '@/views/Global/PrimaryEquipments/PrimaryEquipmentsFilter.vue';
import SelectPrimaryEquipmentTable from './SelectPrimaryEquipmentTable';

export default {
  name: 'SelectPrimaryEquipmentModal',
  components: { SelectPrimaryEquipmentFilter, SelectPrimaryEquipmentTable },
  props: {
    value: { type: Boolean, default: () => false },
    items: { type: Array, default: () => [] },
    incidentId: { type: String, default: () => '' }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value === true) {
          this.key += 1;
        }
      }
    }
  },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        energyObjectIdFilter: [],
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        primaryEquipmentTypeIdFilter: [],
        eoRegionFilter: [],
        skipEoObject: false,
        isLocalNsiFilter: false,
        isRuFilter: false,
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        hideLines: false
      },
      filterActive: true,
      key: 0
    };
  },
  methods: {
    change(event) {
      this.$emit('change', event);
    },
    clearFilters() {
      this.filterModel = {
        nameFilter: '',
        energyObjectIdFilter: [],
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        primaryEquipmentTypeIdFilter: [],
        eoRegionFilter: [],
        skipEoObject: false,
        isLocalNsiFilter: false,
        isRuFilter: false,
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        hideLines: false
      };
    }
  }
};
</script>

<style scoped>



</style>
